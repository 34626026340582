/* INPUT TABLE  */
.input-table {
    width: 100%;
	border: 1px solid var(--color-grey-unknown);
	border-radius: 5px;
	box-shadow: var(--shadow-input);
}

	.input-table thead th {
		text-align: left;
		padding: 8px 15px;
		border-bottom: 1px solid var(--color-grey-unknown);
		font-size: var(--font-size-normal);
		font-weight: 500;
		color: var(--color-dark-grey);
		background-color: var(--color-white);
	}

	.input-table thead th:first-of-type {
		border-radius: 5px 0 0 0;
	}

	.input-table thead th:last-of-type {
		border-radius: 0 5px 0 0;
	}

		.input-table__form-row__actions {
			
		}

			.input-table__form-row__actions__wrapper {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: flex-end;
				gap: 10px;
			}

				.input-table__form-row__actions__submit {
					width: 36px;
					height: 36px;
					border-radius: 18px;
					outline: none;
					border: none;
					background: var(--color-secondary);
					color: var(--color-white);
					box-shadow: var(--shadow-secondary);
					font-size: var(--font-size-extra-large);
					cursor: pointer;
				}

				.input-table__form-row__actions__cancel {
					border: 0;
					width: 22px;
					height: 22px;
					background-color: transparent;
					font-size: var(--font-size-extra-large);
					color: var(--font-color-midtone);
					cursor: pointer;
				}

		.button-row {
			padding: 0 !important;
			text-align: right;
		}

			.button-row__add-button {
				display: flex;
				justify-content: center;
				align-items: center;
				color: var(--color-white);
				width: 100%;
				height: 100%;
				border: 0;
				background-color: var(--color-grey);
				gap: 10px;
				cursor: pointer;
				font-weight: 500;
			}

	.input-table tbody tr:nth-child(even) td {
		background-color: #F5F4F2;
	}

	.input-table tbody tr:nth-child(odd) td {
		background-color: var(--color-white);
	}

	.input-table tbody td {
		padding: 0 15px;
		align-items: center;
		height: 50px;
	}

	.input-table tbody tr:last-of-type td:first-of-type {
		border-radius: 0 0 0 5px;
	}

	.input-table tbody tr:last-of-type td:last-of-type {
		border-radius: 0 0 5px 0;
	}

/* 
!!! IMPORTANT !!!
This rule here takes care of the excessive growing of certain fields.
Might be a subject to change.
*/
.input-table-field {
	width: 100px;
}

.input-table-field .input-title, 
.input-table-field .input-error,
.input-table-field .dropdown-wrapper__label,
.input-table-field-dp .datepicker-label,
.input-table-field-dp .datepicker-error {
	padding: 0;
}

/* CELLS */

.input-table-cell {
	display: flex;
	font-size: var(--font-size-normal);
	text-align: left;
	color: var(--font-color-input);
}

	.input-table-cell-action {
		justify-content: flex-end;
	}

		.input-table-cell-action__transparent-button {
			border: none;
			background-color: transparent;
			cursor: pointer;
		}

/* ROWS */

.input-table-row {
	cursor: pointer;
}

.input-table-row-active td {
	background-color: var(--color-blue) !important;
}

.input-table-row-active .input-table-cell {
	color: var(--color-white);
}