* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Roboto;
    list-style: none;
}

:root {
    /* colors */
    --color-primary: #FF5800;
    --color-primary-faded: #FFF3ED;
    --color-secondary: #359fdc;
    --color-red: #FF0000;
    --color-green: #44A000;
    --color-light-green: #8EB546;
    --color-yellow: #FAD02C;
    --color-medium-blue: #27A0D8;
    --color-white: #FFFFFF;
    --color-body: #F5F4F2;
    --color-grey: #ACACAC;
    --color-light-grey: #E6E5E3;
    --color-dark-grey: #5C5C5C;
    --color-grey-unknown: #C7C7C7;
    --color-blue: #00AADD;
    --color-actions-grey: #70707024;
    --color-actions-shadow: #0000001D;
    --color-light: #A2A2A2;
    --color-grey-medium: #F7F7F7;
    --color-toast-green: #44A000;
    --color-toast-blue: #03A0D8;
    --color-toast-yellow: #FFA700;
    --color-toast-red: #FF0000;
    --background-color-error: #EF0040;



    /* gradients */
    --gradient-login-sidebar: linear-gradient(135deg, #ff5800 0%,#ff3b00 100%);

    /* shoadows */
    --shadow-input: 0 1px 2px rgba(0, 0, 0, .2);
    --shadow-input-inner-focused: inset 0 0 2px rgba(53, 159, 220, 1);
    --shadow-input-inner-error: inset 0 0 2px rgba(255, 0, 0, 1);
    --shadow-primary: 0 5px 15px rgba(0, 0, 0, .1);
    --shadow-wide: 0 0 30px rgba(0, 0, 0, .1);
    --shadow-secondary: 0 5px 15px rgba(0, 170, 221, .3);
    --shadow-orange: 0 5px 15px rgba(255, 88, 0, .35);
    --shadow-panel: 0px 10px 15px #00000008;

    /* border */
    --border-input-normal: 1px solid #C7C7C7;
    --border-darker-gray: 1px solid #D1D1D1;
    --border-input-focused: 1px solid rgb(53, 159, 220);
    --border-input-error: 1px solid rgb(255, 0, 0);

    --border-radius: 5px;
    --border-radius-small: 3px;
    --border-radius-top: 5px 5px 0 0;
    --border-radius-bottom: 0 0 5px 5px;

    /* font */
    --font-size-largest: 28px;
    --font-size-extra-large: 20px;
    --font-size-large: 16px;
    /* --font-size-large: 19px; */
    --font-size-normal: 14px;
    --font-size-small: 12px;
    --font-size-smaller: 11px;

    --font-color-input: #5C5C5C;
    --font-color-light: #ACACAC;
    --font-color-error: #FF0000;
    --font-color-midtone: #898989;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    background-color: var(--color-body);
}

#root {
    width: 100%;
    height: 100%;
}